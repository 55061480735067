import React, { useState } from 'react'
import signImgix from '../../utils/signImgix'
import { device } from '../../utils/screenSizes'
import styled from 'styled-components'

import SocialList from './socialList'

const Author = ({ author, lang, preview = false }) => {
  const [err, setError] = useState(false)

  const name = author.name ? author.name.ar : author.name.en || null
  const title = author.title ? author.title.ar : author.name.en || null
  const email = author.email ? author.email.ar : null
  const avatar = author.avatar ? author.avatar.ar : null
  const biography = author.biography
    ? author.biography.ar
    : author.name.en || null
  const twitter = author.twitter ? author.twitter.ar : null
  const facebook = author.facebook ? author.facebook.ar : null
  const linkedIn = author.linkedIn ? author.linkedIn.ar : null
  const instagram = author.instagram ? author.instagram.ar : null
  const website = author.website ? author.website.ar : null

  let imageUrl = null
  if (avatar && avatar.url) {
    imageUrl = signImgix(
      `${avatar.url}?w=200&h=200&mask=ellipse&fm=png&fit=crop&auto=format&fp-x=${avatar.fpX}&fp-y=${avatar.fpY}`,
      preview
    )
  }

  return (
    <AuthorSection>
      {!err && imageUrl ? (
        <ImageWrapper dir={lang}>
          <img
            src={imageUrl}
            alt={name}
            title={name}
            loading="lazy"
            onError={() => setError(true)}
          />
        </ImageWrapper>
      ) : null}
      <ContentWrapper>
        <Title>{name}</Title>
        <SubTitle>{title}</SubTitle>
        <SocialList
          visible
          options={{ twitter, facebook, linkedIn, instagram, email, website }}
          theme="dark"
        />
        <Bio>{biography}</Bio>
      </ContentWrapper>
    </AuthorSection>
  )
}

export default Author

const AuthorSection = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  width: 100%;
  min-height: 200px;
  margin: 0 auto 15px;
  padding: 15px;
  background: rgb(251, 251, 251);
  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  font-family: 'Neue Haas Grotesk Bloomberg', Arial, Verdana;

  > div {
    flex-grow: 1;
  }

  @media ${device.s} {
    margin: 20px auto 15px;
    flex-flow: column nowrap;
    align-items: center;
  }
`

const ImageWrapper = styled.div`
  flex-grow: 1;
  margin-right: 0;
  margin-left: 20px;

  &[dir='en'] {
    margin-right: 20px;
    margin-left: 0;
  }

  > img {
    max-width: 180px;
  }

  @media ${device.s} {
    width: 140px;
    margin: 0;

    > img {
      max-width: 140px;
    }
  }
`

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column wrap;

  @media ${device.s} {
    align-items: center;
  }
`
const Title = styled.h1`
  font-family: 'Neue Haas Grotesk Bloomberg', Arial, Verdana;
  font-size: 24px;
  font-weight: bold;
  color: rgb(58, 58, 58);
  margin: 0 0 10px 0;
`

const SubTitle = styled.h2`
  font-family: 'Neue Haas Grotesk Bloomberg', Arial, Verdana;
  font-size: 12px;
  color: rgb(210, 28, 35);
  font-weight: normal;
  margin: 0 0 10px 0;
`

const Bio = styled.p`
  font-family: 'Neue Haas Grotesk Bloomberg', Arial, Verdana;
  font-size: 13px;
  color: rgb(108, 108, 108);
  line-height: 20px;
`
