import React from 'react'
import {
  SocialControls,
  SocialOption,
  SocialControlsContainerAuthor,
  TwitterHandle
} from '../socialcontrols/socialcontrols.styled'
import {
  FacebookIcon,
  TwitterIcon,
  EmailIcon,
  LinkedInIcon,
  InstagramIcon,
  WebsiteIcon
} from '../socialcontrols/icons'

const SocialList = ({ visible, options, theme }) => {
  return (
    <SocialControls shareVisible={visible} hideOnMobile={false} width='100%'>
      <SocialControlsContainerAuthor>
        {options.twitter && (
          <SocialOption
            id='twitter'
            key='twitter'
            dir='ltr'
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              window.open(options.twitter)
            }}
            style={{
              display: 'inline-flex',
              flexFlow: 'row nowra',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <TwitterIcon theme='twitter' width='20' height='20' />{' '}
            <TwitterHandle dir='ltr'>
              {options.twitter.replace('https://www.twitter.com/', '@')}
            </TwitterHandle>
          </SocialOption>
        )}
        {options.facebook && (
          <SocialOption
            id='facebook'
            key='facebook'
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              window.open(options.facebook)
            }}
          >
            <FacebookIcon theme='facebook' width='12' height='20' />
          </SocialOption>
        )}

        {options.linkedIn && (
          <SocialOption
            id='linkedIn'
            key='linkedIn'
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              window.open(options.linkedIn)
            }}
          >
            <LinkedInIcon theme='linkedIn' width='20' height='20' />
          </SocialOption>
        )}

        {options.instagram && (
          <SocialOption
            id='instagram'
            key='instagram'
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              window.open(options.instagram)
            }}
          >
            <InstagramIcon
              key='InstagramIcon'
              theme={theme}
              width='20'
              height='20'
            />
          </SocialOption>
        )}

        {options.email && (
          <SocialOption
            id='email'
            key='email'
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              window.open(`mailto:${options.email}`)
            }}
          >
            <EmailIcon key='emailIcon' theme={theme} width='20' height='20' />
          </SocialOption>
        )}

        {options.website && (
          <SocialOption
            id='website'
            key='website'
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              window.open(options.website)
            }}
          >
            <WebsiteIcon
              key='WebsiteIcon'
              theme={theme}
              width='20'
              height='20'
            />
          </SocialOption>
        )}
      </SocialControlsContainerAuthor>
    </SocialControls>
  )
}

export default SocialList
