import React from 'react'
import signImgix from '../../utils/signImgix'
import { device } from '../../utils/screenSizes'
import styled from 'styled-components'
import rtl from 'styled-components-rtl'
import Truncate from 'react-truncate'
import DateTimeService from '../../utils/common'

const AuthorArticles = ({ article, author, lang }) => {
  const title = article[`title_${lang}`]
  const content = article[`content_${lang}`] || null
  const name = author ? author.name[lang] : article.authors[0].name
  const category = article[`categories_${lang}`]

  let imageUrl = null
  if (article.mainImageUrl) {
    imageUrl = signImgix(
      `${article.mainImageUrl}?w=200&h=200&fm=jpg&fit=crop&auto=format`
    )
  }

  return (
    <AuthorSection href={`/${lang}/${article.slug}`} id="article_card">
      <ContentWrapper>
        <Header>
          <Date>
            {DateTimeService.formatInLocalTime(
              article.publishedDate,
              'dd MMMM yyyy ,hh:mm'
            )}
          </Date>
          <Category>{category}</Category>
          <AuthorName>{name}</AuthorName>
        </Header>
        <Title>
          <Truncate lines={2}>{title}</Truncate>
        </Title>
        <SubTitle>{name}</SubTitle>
        <Content>
          <Truncate lines={2}>{content}</Truncate>
        </Content>
      </ContentWrapper>
      <ImageWrapper>
        {imageUrl ? (
          <img src={imageUrl} alt={name} title={name} loading="lazy" />
        ) : null}
      </ImageWrapper>
    </AuthorSection>
  )
}

export default AuthorArticles

const AuthorSection = styled.a`
  font-family: 'Neue Haas Grotesk Bloomberg', Arial, Verdana;
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  width: 100%;
  min-height: 156px;
  max-height: 205px;
  margin: 0 auto 15px;
  background: rgb(251, 251, 251);
  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.03);
  box-sizing: border-box;
  text-decoration: none;

  ${rtl`
      border-bottom-right-radius: 12px;
      border-top-left-radius: 12px;
    `}

  > div {
    flex-grow: 1;
  }
`

const ImageWrapper = styled.div`
  flex-grow: 1;
  ${rtl`
    margin-left: 20px;
  `}

  > img {
    width: 314px;
    object-fit: cover;
    height: 100%;

    ${rtl`
      border-bottom-right-radius: 12px;
    `}
  }

  @media ${device.s} {
    margin: 0;

    > img {
      width: 140px;
    }
  }
`

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  padding: 20px;
  box-sizing: border-box;
`
const Title = styled.h2`
  font-family: 'Neue Haas Grotesk Bloomberg', Arial, Verdana;
  font-size: 26px;
  line-height: 26px;
  font-weight: bold;
  color: rgb(58, 58, 58);
  margin: 0 0 10px 0;
  width: 100%;

  @media ${device.s} {
    font-size: 17px;
    line-height: 18px;
  }
`

const SubTitle = styled.h3`
  font-family: 'Neue Haas Grotesk Bloomberg', Arial, Verdana;
  font-size: 12px;
  color: #787878;
  font-weight: normal;
  margin: 0;
  display: none;
  width: 100%;

  @media ${device.s} {
    display: block;
  }
`

const AuthorName = styled.div`
  display: block;
  font-family: 'Neue Haas Grotesk Bloomberg', Arial, Verdana;
  font-size: 14px;
  color: #424242;
  font-weight: normal;
  width: 100%;

  ${rtl`
    padding-left: 5px;
  `}

  @media ${device.s} {
    display: none;
  }
`

const Content = styled.div`
  font-family: 'Neue Haas Grotesk Bloomberg', Arial, Verdana;
  font-size: 15px;
  color: rgb(108, 108, 108);
  line-height: 20px;
  width: 100%;

  @media ${device.s} {
    font-size: 12px;
  }
`

const Header = styled.div`
  font-family: 'Neue Haas Grotesk Bloomberg', Arial, Verdana;
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  white-space: nowrap;
  align-items: center;
  line-height: 14px;
  padding: 10px 0;

  @media ${device.s} {
    line-height: 12px;
    padding: 4px 0;
  }
`
const Date = styled.div`
  color: rgb(51, 51, 51);
  font-size: 14px;

  ${rtl`
    border-right: 1px solid #acacac;
    padding-right: 5px;
  `}

  @media ${device.s} {
    font-size: 12px;
  }
`
const Category = styled.div`
  color: rgb(51, 51, 51);
  font-size: 14px;
  padding: 0 5px;

  ${rtl`
    border-right: 1px solid #acacac;
    padding-right: 5px;
  `}

  @media ${device.s} {
    font-size: 12px;
    border: 0;
  }
`
