import React, { useEffect, useState, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from '@reach/router'
import queryString from 'query-string'
import styled, { ThemeProvider } from 'styled-components'
import Pagination from 'rc-pagination'

import base from '../sagas/base'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Author from '../components/author/author'

import { GridError, Spinner } from '../components/grid/grid.styled'
import AuthorArticles from '../components/articleCard'
import {
  ArrowNextIcon,
  ArrowPrevIcon
} from '../components/socialcontrols/icons'

const pageLimit = 7
let startIndex = null
let endIndex = null

const AuthorPreview = ({ location }) => {
  const [authorFetching, setAuthorFetching] = useState(false)
  const [author, setAuthor] = useState(null)
  const [hasAuthorError, setHasAuthorError] = useState(false)
  const loc = useLocation()

  const [accessError, setAccessError] = useState(false)
  const [articleFetching, setArticleFetching] = useState(false)
  const [articleError, setArticleError] = useState(false)

  const [articles, setArticles] = useState([])
  const [activePage, setCurrentPage] = useState(1)
  const [currentData, setCurrentData] = useState([])

  let result = null

  useEffect(() => {
    if (!author) {
      getData()
    }
  }, [])

  useLayoutEffect(() => {
    endIndex = activePage * pageLimit
    startIndex = endIndex - pageLimit
    setCurrentData(articles.slice(startIndex, endIndex))
  }, [activePage, articles])

  const authorId = queryString.parse(loc.search).id || null
  const lang = queryString.parse(loc.search).lang || 'ar'
  const spaceId = queryString.parse(loc.search).spaceId || null

  const getData = async () => {
    const accessToken = queryString.parse(loc.search).accessToken

    if (accessToken == null) {
      setAccessError(true)
      return null
    }

    setAuthorFetching(true)

    const hasAccess = await base.testAdminToken(accessToken)

    if (!hasAccess) {
      setAccessError(true)
      return null
    }

    // search author by id
    try {
      result = await base.getPreview(accessToken, {
        id: authorId,
        lang,
        spaceId
      })

      setAuthor({ ...result.data.data })
      setAuthorFetching(false)
      // fetch articles
      getArticles(result, authorId, accessToken)
    } catch (ex) {
      setHasAuthorError(true)
    }
  }

  const getArticles = async (result, authorId, accessToken) => {
    let authorName
    // search articles by author name
    try {
      // if given id is an article then find the author
      if (result.data.type && result.data.type === 'article') {
        const authorWithId = result.data.authors.map(
          item => item && item.id && item.id === authorId
        )
        if (authorWithId.length === 0) {
          authorName = result.data.authors[0].name
        }
      } else {
        authorName = result.data.data.name
      }

      setArticleFetching(true)
      const name = authorName[lang] || authorName.ar
      const articleResult = await base.search(accessToken, {
        author: encodeURIComponent(name)
      })
      setArticles([...articleResult.data.data])
      setArticleFetching(false)
    } catch (err) {
      setArticleError(err.message)
      setArticleFetching(false)
    }
  }

  if (accessError) {
    return (
      <Layout location={location} title='Author' lang={lang}>
        <SEO title='Preview' lang='en' location={location} />
        <GridError>Access denied</GridError>
      </Layout>
    )
  }

  if (hasAuthorError) {
    return (
      <Layout location={location} title='Author' lang={lang}>
        <SEO title='Preview' lang='en' location={location} />
        <GridError>Failed to find author details</GridError>
      </Layout>
    )
  }

  const isRtl = lang === 'ar'
  const theme = {
    dir: isRtl ? 'rtl' : 'ltr'
  }

  let name = ''
  if (author) {
    name = author.name[lang] || author.name.ar
  }

  return (
    <ThemeProvider theme={theme}>
      <Layout location={location} title='Author' lang={lang}>
        <SEO title='Author' lang={lang} location={location} />

        {authorFetching ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '400px'
            }}
          >
            <Spinner />
          </div>
        ) : (
          <>
            {author && <Author author={author} lang={lang} preview />}

            {articleFetching ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  minHeight: '400px'
                }}
              >
                <Spinner />
              </div>
            ) : null}

            {articleError ? (
              <GridError>Article listing {articleError}</GridError>
            ) : null}

            {articles.length ? (
              <ArticleHeader>
                <AuthorSection>
                  {isRtl ? 'بقلم' : 'Written by'} <strong>{name}</strong>
                </AuthorSection>
                <ShowTotal>
                  {isRtl ? 'عرض النتائج من' : 'Showing results'}{' '}
                  <span>
                    {startIndex + 1} -{' '}
                    {articles.length < endIndex ? articles.length : endIndex}
                  </span>
                </ShowTotal>
              </ArticleHeader>
            ) : null}

            {currentData.map(article => (
              <AuthorArticles
                key={article.item_id}
                article={article}
                author={author}
                lang={lang}
              />
            ))}

            <PaginationWrapper>
              <Pagination
                hideOnSinglePage
                locale='ar'
                pageSize={pageLimit}
                total={articles.length}
                current={activePage}
                onChange={num => setCurrentPage(num)}
                nextIcon={
                  <ArrowNextIcon
                    theme='grey'
                    width='16px'
                    height='16px'
                    flip={isRtl}
                  />
                }
                prevIcon={
                  <ArrowPrevIcon
                    theme='grey'
                    width='16px'
                    height='16px'
                    flip={isRtl}
                  />
                }
              />
            </PaginationWrapper>
          </>
        )}
      </Layout>
    </ThemeProvider>
  )
}

export default AuthorPreview

AuthorPreview.propTypes = {
  location: PropTypes.object
}

AuthorPreview.defaultProps = {
  location: {}
}

const ArticleHeader = styled.div`
  display: flex;
  margin: 20px 0;
  flex-flow: row nowrap;
  justify-content: space-between;
  font-family: 'Neue Haas Grotesk Bloomberg', Arial, Verdana;
`
const AuthorSection = styled.div`
  font-size: 21px;
`
const ShowTotal = styled.div`
  font-size: 19px;
  font-weight: normal;
  color: #3a3a3a;
`

const PaginationWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 20px;
`
